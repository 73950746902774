<template>
  <div
    ref="triggerlistDiv"
    class="triggerList"
  >
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <TriggerListInfo :triggers="triggers" />

      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-3 mb-2">
          <button
            class="btn btn-primary btn-sm mt-1"
            :disabled="selectedTriggers.length == 0"
            @click="show"
          >
            {{ $t("acknowledgeSelected") }}
          </button>
          <button
            slot="button"
            v-tooltip="'Share Filter URL'"
            class="btn btn-sm mt-1 btn-primary ml-2"
            @click="createUrlFilter"
          >
            <font-awesome-icon
              class="mr-1"
              icon="external-link-alt"
            />
          </button>
        </div>
        <div
          v-if="triggers"
          class="col-12 col-md-12 col-lg-12 col-xl-9 mb-2"
        >
          <div class="input-group">
            <toggle-button
              slot="button"
              v-model="toggleEnv"
              :labels="{ checked: 'Stage', unchecked: 'Prod' }"
              :width="80"
              :height="30"
              :font-size="12"
              class="mr-2 mt-1"
              @change="getTriggers"
            />
            <div class="input-prepend">
              <label class="mr-3 mt-2 mb-0">SLA:</label>
            </div>
            <Multiselect
              ref="triggerListSlaMultiselect"
              v-model="filterInstallationSla"
              :options="slaTypes"
              :taggable="false"
              :show-labels="true"
              :searchable="true"
              :close-on-select="true"
              :hide-selected="true"
              :multiple="true"
              class="
                m-input m-input--air
                alt-pointer
                d-inline-block
                form-control
                shadow-none
                border-0
                m-0
                p-0
                mr-3
              "
              deselect-label="Click to deselect"
              select-label="Click to select"
              placeholder="Filter by SLA Agreement"
              get-trig
              @input="getTriggers"
            >
              <template
                slot="option"
                slot-scope="{ option }"
              >
                <span>{{ option }}</span>
              </template>
              <template
                slot="tag"
                slot-scope="{ option }"
              >
                <span
                  class="m-badge m-badge--primary m-badge--wide"
                  @click="deselectSlaOption(option)"
                >{{ option }} &times;</span>
              </template>
            </Multiselect>
            <div class="input-prepend">
              <label
                class="mr-3 mt-2 mb-0"
              >{{ $t("installationList.country") }}:</label>
            </div>
            <Multiselect
              ref="triggerListCountryMultiselect"
              v-model="filterInstallationCountry"
              :options="installationCountries"
              :taggable="false"
              :show-labels="true"
              :searchable="true"
              :close-on-select="true"
              :hide-selected="true"
              :multiple="true"
              class="
                m-input m-input--air
                alt-pointer
                d-inline-block
                form-control
                shadow-none
                border-0
                m-0
                p-0
                mr-3
              "
              deselect-label="Click to deselect"
              select-label="Click to select"
              placeholder="Filter by Country"
              get-trig
              @input="getTriggers"
            >
              <template
                slot="option"
                slot-scope="{ option }"
              >
                <span>{{ option }}</span>
              </template>
              <template
                slot="tag"
                slot-scope="{ option }"
              >
                <span
                  class="m-badge m-badge--primary m-badge--wide"
                  @click="deselectCountryOption(option)"
                >{{ option }} &times;</span>
              </template>
            </Multiselect>
            <div class="input-prepend">
              <label
                class="mr-3 mt-2 mb-0"
              >{{ $t("installationType") }}:</label>
            </div>
            <Multiselect
              ref="triggerListMultiselect"
              v-model="filterInstallationType"
              :options="installationTypes"
              :taggable="false"
              :show-labels="true"
              :searchable="true"
              :close-on-select="true"
              :hide-selected="true"
              :multiple="true"
              class="
                m-input m-input--air
                alt-pointer
                d-inline-block
                form-control
                shadow-none
                border-0
                m-0
                p-0
              "
              deselect-label="Click to deselect"
              select-label="Click to select"
              placeholder="Filter by Installation type"
              get-trig
              @input="getTriggers"
            >
              <template
                slot="option"
                slot-scope="{ option }"
              >
                <span>{{ option }}</span>
              </template>
              <template
                slot="tag"
                slot-scope="{ option }"
              >
                <span
                  class="m-badge m-badge--primary m-badge--wide"
                  @click="deselectOption(option)"
                >{{ option }} &times;</span>
              </template>
            </Multiselect>
            <div class="input-prepend">
              <label class="ml-1 mr-1 mt-2 mb-0">Exclude</label>
            </div>
            <input
              v-model="exclude"
              type="checkbox"
              class="rounded"
              style="margin-bottom: 8px"
              @change="getTriggers"
            >
          </div>
        </div>
      </div>

      <Grid
        :ref="kgm_ref"
        :style="{ height: 'auto' }"
        :data-items="kgm_computedGridItems(triggers)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(triggers)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode,
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick, selectItem"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
        @selectionchange="onSelectionChange"
        @headerselectionchange="onHeaderSelectionChange"
      >
        <div
          slot="informationFilter"
          slot-scope="{ props, methods }"
          class="input-group"
        >
          <input
            ref="informationFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an information"
            @input="
              (event) => {
                methods.change({
                  operator: 'contains',
                  field: props.field,
                  value: event.target.value,
                  syntheticEvent: event,
                });
              }
            "
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="
                kgm_resetInput('informationFilterInput', (event) => {
                  methods.change({
                    operator: '',
                    field: '',
                    value: '',
                    syntheticEvent: event,
                  });
                })
              "
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="priorityFilter"
          slot-scope="{ props, methods }"
          class="input-group"
        >
          <select
            ref="prioritySelect"
            :key="`typeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="
              (event) => {
                methods.change({
                  operator: 'eq',
                  field: props.field,
                  value: event.target.value,
                  syntheticEvent: event,
                });
              }
            "
          >
            <option
              value=""
              disabled
            >
              {{ $t("pleaseSelectOne") }}
            </option>
            <option
              v-for="(type, key) in priorityTypes"
              :key="`triggerList-prioritySelectOption-${key}`"
              :value="type.num"
              :selected="
                kgm_selectDefaultOption(props, type.num, 'prioritySelect')
              "
            >
              {{ type.text }}
            </option>
          </select>
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="
                kgm_resetSelect('prioritySelect', (event) => {
                  methods.change({
                    operator: '',
                    field: '',
                    value: '',
                    syntheticEvent: event,
                  });
                })
              "
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="acknowledgeFilter"
          slot-scope="{ props, methods }"
        >
          <BooleanFilter
            label-true="acknowledged"
            label-false="unacknowledged"
            :value="props.value"
            @changeFilter="
              (ev) => {
                methods.change({
                  operator: ev.operator,
                  field: ev.field,
                  value: ev.value,
                  syntheticEvent: ev.syntheticEvent,
                });
              }
            "
          />
        </div>
        <template
          slot="borderTemplate"
          slot-scope="{ props }"
        >
          <td
            :style="`border-left:4px solid ${triggerPriorityMixin_priorityClass(
              props.dataItem.lastEventSeverity
            )}`"
          />
        </template>
        <template
          slot="informationTemplate"
          slot-scope="{ props }"
        >
          <td>
            <router-link
              :to="`/installation/${props.dataItem.installation}`"
              class="alt-primary-color"
            >
              <span>{{ props.dataItem.hosts[0].name }}</span>
            </router-link>
            <span>: {{ props.dataItem.description }}</span>
          </td>
        </template>
        <template
          slot="priorityTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <span>{{
              triggerPriorityMixin_priorityText(
                props.dataItem.lastEventSeverity
              )
            }}</span>
          </td>
        </template>
        <template
          slot="dateTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <span
              v-tooltip="
                dateTime_dateTime(
                  kgm_getNestedValue(props.field, props.dataItem)
                )
              "
            >{{
              dateTime_fromNow(
                kgm_getNestedValue(props.field, props.dataItem)
              )
            }}</span>
          </td>
        </template>
        <template
          slot="acknowledgeTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <router-link
              v-if="!props.dataItem.acknowledged"
              :to="`/problem/acknowledge/${props.dataItem.triggerId}/${props.dataItem.installation}/installation`"
              class="btn btn-primary btn-block btn-sm"
            >
              Acknowledge
            </router-link>
            <div v-else>
              <span>acknowledged</span>
              <router-link
                :to="`/problem/acknowledge/${props.dataItem.triggerId}/${props.dataItem.installation}/installation`"
                class="symbol ml-2"
              >
                <font-awesome-icon icon="edit" />
              </router-link>
            </div>
          </td>
        </template>
        <template
          slot="itopsTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <button
              class="btn btn-primary btn-block btn-sm"
              @click="createItopsIssue(props.dataItem)"
            >
              <span>Create ITOPS</span>
            </button>
          </td>
        </template>
      </Grid>
    </template>

    <Sidebar
      v-if="showSidebar"
      :show-sidebar="showSidebar"
      @close="close"
    >
      <AcknowledgeAllTriggersSidebar
        :pre-selected-triggers="selectedTriggers"
        @reload="reloadTriggers"
        @close="close"
      />
    </Sidebar>
  </div>
</template>

<script>
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
import { triggerPriorityMixin } from "@/mixins/triggerPriorityMixin.js";
import { installationTypeMixin } from "@/mixins/installationTypeMixin.js";
import Sidebar from "@/components/Base/Sidebar.vue";
import AcknowledgeAllTriggersSidebar from "@/components/Problem/AcknowledgeAllTriggersSidebar.vue";
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { mapGetters } from "vuex";
import { urlParseMixin } from "@/mixins/urlParseMixin";

export default {
  name: "TriggerList",
  metaInfo() {
    return {
      title: this.title,
    };
  },
  components: {
    TriggerListInfo: () => import("@/components/Problem/TriggerListInfo.vue"),
    BooleanFilter: () => import("@/components/CustomFilters/BooleanFilter.vue"),
    Multiselect: () => import("vue-multiselect"),
    Sidebar,
    AcknowledgeAllTriggersSidebar,
  },
  mixins: [
    dateTimeMixin,
    triggerPriorityMixin,
    kendoGridMixin,
    installationTypeMixin,
    authenticationMixin,
    urlParseMixin
  ],
  props: {
    autoRefresh: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      exclude: false,
      acknowledgeFilterSelect: "",
      loading: true,
      selectedField: "selected",
      showSidebar: false,
      selectedTriggers: [],
      kgm_storage: "TriggerList",
      filterInstallationType: [],
      filterInstallationCountry: [],
      filterInstallationSla: [],
      installationCountries: [],
      toggleEnv: false,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "nameAndDescription",
            operator: "contains",
            value: "",
          },
        ],
      },
      kgm_sort: [
        {
          field: "lastEventSeverity",
          dir: "desc",
        },
        {
          field: "lastchange",
          dir: "desc",
        },
      ],
      kgm_columns: [
        {
          cell: "borderTemplate",
          filterable: false,
          width: "4px",
        },
        {
          field: "selected",
          width: "50px",
          headerSelectionValue: this.areAllSelected,
          filterable: false,
        },
        {
          field: "nameAndDescription",
          filterable: true,
          filter: "text",
          title: "Information",
          cell: "informationTemplate",
          filterCell: "informationFilter",
        },
        {
          field: "lastEventSeverity",
          filterable: true,
          filter: "text",
          title: "Priority",
          cell: "priorityTemplate",
          width: "175px",
          filterCell: "priorityFilter",
          hideOn: ["lgDown"],
        },
        {
          field: "lastchange",
          filterable: false,
          filter: "date",
          title: "Changed",
          cell: "dateTemplate",
          width: "175px",
          hideOn: ["lgDown"],
        },
        {
          field: "acknowledged",
          filterable: true,
          filter: "boolean",
          title: "Acknowledge",
          cell: "acknowledgeTemplate",
          width: "150px",
          filterCell: "acknowledgeFilter",
          hideOn: ["mdDown"],
        },
        {
          field: "itops",
          filterable: false,
          title: "Issue",
          cell: "itopsTemplate",
          width: "150px",
          hidden: !(
            this.authenticationHasRole("scope_staff") &&
            (this.authenticationHasRole("secondlevel") ||
              this.authenticationHasRole("admin"))
          ),
        },
      ],
      triggers: null,
      lastRefresh: null,
      filterSet: {
        disaster: false,
        high: false,
        average: false,
        warning: false,
      },
      height: 0,
      cancelSource: null,
      slaTypes: [
        "ExternalHosted-Bronze",
        "ExternalHosted-Silver",
        "ExternalHosted-Gold",
        "Summer-and-Winter",
        "Summer",
        "Winter",
        "Gold",
        "Silver",
        "Bronze",
      ],
    };
  },
  computed: {
    ...mapGetters(["store_getJiraUrl"]),
    priorityTypes() {
      if (!this.triggers) return [""];
      //Distinct (include every entry only once)
      let priorityTypes = Array.from(
        new Set(this.triggers.map((o) => o.lastEventSeverity))
      );
      //Remove null values
      priorityTypes = priorityTypes.filter((e) => {
        return e;
      });
      priorityTypes = priorityTypes.map((e) => {
        return { text: this.triggerPriorityMixin_priorityText(e), num: e };
      });
      return priorityTypes.sort();
    },
    installationTypes() {
      var types = this.installationTypeMixin_allInstallationTypes();
      return types.sort();
    },
    installationTypeFilterApi() {
      if (!this.filterInstallationType) {
        return "";
      }
      let convertedFilter = this.filterInstallationType.map((e) => {
        return this.installationTypeMixin_installationTypeKey(e);
      });
      return convertedFilter;
    },
    areAllSelected() {
      return this.triggers.findIndex((item) => item.selected === false) === -1;
    },
  },
  watch: {
    filterInstallationSla(val) {
      localStorage["triggerListFilter"] = JSON.stringify({
        filterInstallationType: this.filterInstallationType,
        filterInstallationCountry: this.filterInstallationCountry,
        toggleEnv: this.toggleEnv,
        filterInstallationSla: val,
        exclude: this.exclude,
      });
    },
    filterInstallationCountry(val) {
      localStorage["triggerListFilter"] = JSON.stringify({
        filterInstallationType: this.filterInstallationType,
        filterInstallationCountry: val,
        filterInstallationSla: this.filterInstallationSla,
        toggleEnv: this.toggleEnv,
        exclude: this.exclude,
      });
    },
    filterInstallationType(val) {
      localStorage["triggerListFilter"] = JSON.stringify({
        filterInstallationType: val,
        filterInstallationCountry: this.filterInstallationCountry,
        filterInstallationSla: this.filterInstallationSla,
        toggleEnv: this.toggleEnv,
        exclude: this.exclude,
      });
    },
    exclude(val) {
      localStorage["triggerListFilter"] = JSON.stringify({
        filterInstallationType: this.filterInstallationType,
        filterInstallationCountry: this.filterInstallationCountry,
        filterInstallationSla: this.filterInstallationSla,
        toggleEnv: this.toggleEnv,
        exclude: val,
      });
    },
    toggleEnv(val) {
      localStorage["triggerListFilter"] = JSON.stringify({
        filterInstallationType: this.filterInstallationType,
        filterInstallationCountry: this.filterInstallationCountry,
        filterInstallationSla: this.filterInstallationSla,
        exclude: this.exclude,
        toggleEnv: val,
      });
    },
  },
  created() {
    if (!this.kgm_filter) {
      this.initializeFilter();
    }

    if (this.$route.query) {
      this.loadQueryFilter(
        this.$route.query.priority,
        "lastEventSeverity",
        "eq"
      );
      this.loadQueryFilter(
        this.$route.query.ack != null ? this.$route.query.ack == "true" : null,
        "acknowledged",
        "eq"
      );
      if (this.$route.query.name) {
        this.loadQueryFilter(
          this.$route.query.name,
          "nameAndDescription",
          "contains"
        );
      }
    }
    this.loadSessionFilter();
    this.getCountries();
    this.getTriggers();
    this.$eventBus.$on("triggerListInfo_setFilter", this.setFilter);
  },
  beforeMount() {
    //Every 3 minutes
    this.refreshTriggers = window.setInterval(() => {
      this.autoLoadTriggers();
    }, 60000 * 3);
  },
  beforeDestroy() {
    window.clearInterval(this.refreshTriggers);
    this.cancelRequest();
    this.$eventBus.$off("triggerListInfo_setFilter", this.setFilter);
  },
  methods: {
    jiraUrl (endpoint) {
      return this.urlParseMixin_combine(
        this.store_getJiraUrl,
        `browse/${endpoint}`
      );
    },
    createUrlFilter() {
      var queryObj = {};
      if (
        this.filterInstallationType &&
        this.filterInstallationType.length > 0
      ) {
        queryObj.types = this.filterInstallationType.join(",");
      }

      if (
        this.filterInstallationCountry &&
        this.filterInstallationCountry.length > 0
      ) {
        queryObj.countries = this.filterInstallationCountry.join(",");
      }

      if (this.filterInstallationSla && this.filterInstallationSla.length > 0) {
        queryObj.slas = this.filterInstallationSla.join(",");
      }

      queryObj.exclude = this.exclude;
      queryObj.toggleEnv = this.toggleEnv;

      var nameFilter = this.kgm_filter.filters.find(
        (o) => o.field == "nameAndDescription"
      );
      if (nameFilter) {
        queryObj.name = nameFilter.value;
      }

      var prioFilter = this.kgm_filter.filters.find(
        (o) => o.field == "lastEventSeverity"
      );
      if (prioFilter) {
        queryObj.priority = prioFilter.value;
      }

      var ackFilter = this.kgm_filter.filters.find(
        (o) => o.field == "acknowledged"
      );
      if (ackFilter) {
        queryObj.ack = ackFilter.value;
      }

      const params = new URLSearchParams(queryObj);
      var url = `${window.location.origin}/problem?${params.toString()}`;
      navigator.clipboard.writeText(url);
      this.$snotify.success("The URL has been copied to your clipboard.");
    },
    show() {
      this.showSidebar = true;
    },
    close() {
      this.showSidebar = false;
    },
    deselectOption(option) {
      if (option == null) {
        this.filterInstallationType = [];
        return;
      }
      let index = this.filterInstallationType.indexOf(option);
      this.filterInstallationType.splice(index, 1);
      this.getTriggers();
    },
    deselectCountryOption(option) {
      if (option == null) {
        this.filterInstallationCountry = [];
        return;
      }
      let index = this.filterInstallationCountry.indexOf(option);
      this.filterInstallationCountry.splice(index, 1);
      this.getTriggers();
    },
    deselectSlaOption(option) {
      if (option == null) {
        this.filterInstallationSla = [];
        return;
      }
      let index = this.filterInstallationSla.indexOf(option);
      this.filterInstallationSla.splice(index, 1);
      this.getTriggers();
    },
    setFilter(priority, unAcknowledged) {
      let oldFilterIndex = this.kgm_filter.filters.indexOf(
        this.kgm_filter.filters.find((o) => o.field == "lastEventSeverity")
      );
      if (oldFilterIndex >= 0) {
        this.kgm_filter.filters.splice(oldFilterIndex, 1);
      }
      let oldFilterIndexAcknowledged = this.kgm_filter.filters.indexOf(
        this.kgm_filter.filters.find((o) => o.field == "acknowledged")
      );
      if (oldFilterIndexAcknowledged >= 0) {
        this.kgm_filter.filters.splice(oldFilterIndexAcknowledged, 1);
      }

      this.kgm_filter.filters.push({
        field: "lastEventSeverity",
        operator: "eq",
        value: priority,
      });
      if (unAcknowledged) {
        this.kgm_filter.filters.push({
          field: "acknowledged",
          operator: "eq",
          value: false,
        });
      }
    },
    loadQueryFilter(val, field, operator) {
      if (val != null) {
        let filter = this.kgm_filter.filters.find((f) => f.field == field);
        if (filter) {
          filter.value = val;
        } else {
          let newFilter = {
            field: field,
            operator: operator,
            value: val,
          };

          this.kgm_filter.filters.push(newFilter);
        }
      }
    },
    loadSessionFilter() {
      let loadedStorage = localStorage["triggerListFilter"];
      if (!loadedStorage) {
        return;
      }
      let parsedStorage = JSON.parse(loadedStorage);
      if (this.$route.query.types) {
        this.filterInstallationType = this.$route.query.types.split(",");
      } else if (
        parsedStorage.filterInstallationType &&
        parsedStorage.filterInstallationType.length > 0
      ) {
        this.filterInstallationType = parsedStorage.filterInstallationType;
      }

      if (this.$route.query.countries) {
        this.filterInstallationCountry = this.$route.query.countries.split(",");
      } else if (
        parsedStorage.filterInstallationCountry &&
        parsedStorage.filterInstallationCountry.length > 0
      ) {
        this.filterInstallationCountry =
          parsedStorage.filterInstallationCountry;
      }

      if (this.$route.query.slas) {
        this.filterInstallationSla = this.$route.query.slas.split(",");
      } else if (
        parsedStorage.filterInstallationSla &&
        parsedStorage.filterInstallationSla.length > 0
      ) {
        this.filterInstallationSla = parsedStorage.filterInstallationSla;
      }

      if (this.$route.query.exclude) {
        this.exclude = this.$route.query.exclude;
      } else if (
        parsedStorage.exclude != null ||
        parsedStorage.exclude != undefined
      ) {
        this.exclude = parsedStorage.exclude;
      }

      if (this.$route.query.toggleEnv) {
        this.toggleEnv = this.$route.query.toggleEnv;
      } else if (
        parsedStorage.toggleEnv != null ||
        parsedStorage.toggleEnv != undefined
      ) {
        this.toggleEnv = parsedStorage.toggleEnv;
      }
    },
    initializeFilter() {
      this.kgm_filter = {
        logic: "and",
        filters: [
          {
            field: "nameAndDescription",
            operator: "contains",
            value: "",
          },
        ],
      };
    },
    //#region Helpers
    createGroupedTriggers() {
      if (this.triggers == null) {
        this.loading = false;
        return;
      }
      this.triggers.forEach((trigger) => {
        trigger.priorityText = this.triggerPriorityMixin_priorityText(
          trigger.lastEventSeverity
        );
        trigger.nameAndDescription = `${
          trigger.hosts[0] ? trigger.hosts[0].name : ""
        } ${trigger.description}`;
        if (!trigger.acknowledged) {
          trigger.acknowledgeText = "unacknowledged";
        } else {
          trigger.acknowledgeText = "acknowledged";
        }
      });
      this.lastRefresh = Date.now();
      this.loading = false;
    },
    onHeaderSelectionChange(event) {
      let checked = event.event.target.checked;
      this.$set(
        this,
        "triggers",
        this.triggers.map((item) => {
          return { ...item, selected: !item.acknowledged ? checked : false };
        })
      );
      this.selectedTriggers = this.kgm_computedAllGridItems(
        this.triggers
      ).filter((x) => {
        return x.selected;
      });
    },
    onSelectionChange(event) {
      if (!event.dataItem.acknowledged) {
        this.$set(
          event.dataItem,
          this.selectedField,
          !event.dataItem[this.selectedField]
        );
      } else {
        event.event.target.checked = false;
      }
      this.selectedTriggers = this.kgm_computedAllGridItems(
        this.triggers
      ).filter((x) => {
        return x.selected;
      });
    },
    selectItem(event) {
      if (!event.dataItem.acknowledged) {
        this.$set(
          event.dataItem,
          this.selectedField,
          !event.dataItem[this.selectedField]
        );
      } else {
        event.event.target.checked = false;
      }
      this.selectedTriggers = this.kgm_computedAllGridItems(
        this.triggers
      ).filter((x) => {
        return x.selected;
      });
    },
    //#endregion

    //#region API-calls
    autoLoadTriggers() {
      if (this.autoRefresh) {
        this.getTriggers();
      }
    },
    cancelRequest() {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
    },
    getCountries: function () {
      this.axios
        .get("/Installation/GetCountries")
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.installationCountries = response.data;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        });
    },
    getTriggers: function () {
      this.loading = true;
      this.cancelRequest();
      this.cancelSource = this.axios.CancelToken.source();
      //let requestConfig = { cancelToken: this.cancelSource.token };
      let requestConfig = {
        params: {
          installationTypes: this.installationTypeFilterApi,
          countries: this.filterInstallationCountry,
          slas: this.filterInstallationSla,
          exclude: this.exclude,
          stage: this.toggleEnv,
        },
        cancelToken: this.cancelSource.token,
      };
      this.axios
        .get("/MonitoringSystem/GetAllTriggers", requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.cancelSource = null;
          this.triggers = response.data;
          this.triggers = this.triggers.map((item) => {
            return { ...item, selected: false };
          });
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.createGroupedTriggers();
        });
    },
    reloadTriggers() {
      this.getTriggers();
      this.$eventBus.$on("triggerListInfo_setFilter", this.setFilter);
    },
    createItopsIssue(trigger) {
      this.axios.post(`/Issue/CreateItopsIssue`, trigger)
        .then((response) => {
          if(response.data) { 
            window.open(this.jiraUrl(response.data), '_blank');
          }
          this.reloadTriggers()
        })
    },
    //#endregion
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
#problemgrid .k-grid-content {
  min-height: 400px !important;
}
.triggerList .k-input {
  margin-right: 0;
  padding-right: 0;
}
.triggerList .k-grid table tr:hover td {
  background: rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>
