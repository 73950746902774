<template>
  <div class="acknowledgeAllTriggersSidebar">
    <LoadingPlaceholder v-if="loading" />
    <template v-else-if="preSelectedTriggers">
      <div
        :class="['mb-3', { 'is-invalid': errors.has(`TriggerIds`) }]"
      >
        <h4 class="m-0 p-0 mb-1">
          {{ $t('triggers') }}
        </h4>
        <hr class="m-0 p-0 mb-2">
        <ul class="m-0 p-0">
          <li
            v-for="(trigger, triggerIndex) in preSelectedTriggers"
            :key="`acknowledgeProblemAllTriggerIndexListItem-${ triggerIndex }`"
          >
            <label class="m-checkbox">
              <input
                v-model="selectedTriggers"
                :value="trigger"
                type="checkbox"
                class="mr-2"
                checked
              >
              <input type="checkbox"> {{ trigger.hosts[0].name }}: {{ trigger.description }}
              <span />
            </label>
            <!-- <br> -->
            <!-- <span class="ml-4">{{ trigger.comments }}</span> -->
          </li>
        </ul>
        <span
          v-show="errors.has(`TriggerIds`)"
          class="badge badge-danger"
        >{{ errors.first(`TriggerIds`) }}</span>
      </div>

      <div
        v-if="preSelectedTriggers"
        class="column"
      >
        <div>
          <div class="form-group m-form__group">
            <label>{{ $t('message') }}</label>
            <textarea
              v-model="message"
              v-focus
              :class="['form-control m-input', { 'is-invalid': errors.has(`Message`) }]"
              placeholder=""
              rows="5"
              @keydown.ctrl.enter="acknowledge()"
            />
            <span
              v-show="errors.has(`Message`)"
              class="badge badge-danger"
            >{{ errors.first(`Message`) }}</span>
          </div>
          <div class="clearfix" />
        </div>
        <div class="row">
          <div class="form-check form-check-inline mb-3">
            <input
              id="changeSeverityCheckbox"
              v-model="changeServerity"
              class="form-check-input"
              type="checkbox"
            >
            <label
              class="form-check-label"
              for="changeSeverityCheckbox"
            >Change Severity</label>
          </div>
          <select
            v-if="changeServerity"
            id="converterTypes"
            v-model="selectedSeverity"
            class="form-control mb-4"
          >
            <option
              v-for="(severity, index) in severities"
              :key="`converterType-converterTypeOption-${ index }`"
              :value="index+''"
            >
              {{ severity }}
            </option>
          </select>
        </div>
        <div>
          <label>{{ $t('possibleMessages') }}</label>
          <button
            v-for="(item, index) in messages"
            :key="`acknowledgeProblem-messageButton-${ index }`"
            class="btn btn-block btn-secondary"
            @click="setMessage(item.key)"
          >
            {{ item.key }}
          </button>
        </div>

        <button
          class="btn btn-primary float-right mt-5"
          :disabled="selectedTriggers.length == 0"
          @click.prevent="acknowledge()"
        >
          {{ $t('acknowledgeSelected') }}
        </button>
      </div>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';
import acknowledgeReportAdapter from '@/adapter/acknowledgeReportAdapter.js';

export default {
  name: "AcknowledgeAllTriggersSidebar",
  mixins: [
    acknowledgeReportAdapter,
    errorMixin
  ],
  props: {
    preSelectedTriggers: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedTriggers: [],
      message: "",
      messages: null,
      loading: false,
      changeServerity: false,
      selectedSeverity: null,
      severities: ['Not classified', 'Information', 'Warning', 'Average', 'High', 'Disaster']
    }
  },
  created () {
    this.selectedTriggers = this.preSelectedTriggers;
    this.getAcknowledgeMessages();
  },
  methods: {
    setMessage (key) {
      let lowerCaseKey = key.toLowerCase();
      let messageToSet = this.messages.find(message => {
        return message.key.toLowerCase() === lowerCaseKey;
      });
      if (messageToSet == null) {
        return;
      }
      this.message = messageToSet.message;
    },
    getAcknowledgeMessages: function () {
      this.loading = true;
      this.axios.get('/AcknowledgeMessages/GetAllAcknowledgeMessages')
        .then((response) => {
          if (response == null) {
            return;
          }
          this.messages = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    acknowledge () {
      this.loading = true;
      let action = 0;
      if(this.changeServerity) {
        action = 8;
      }
      let acknowledgeRequest = this.sendAcknowledgeSelectedReport(this.message, this.selectedTriggers, action, this.selectedSeverity);
        acknowledgeRequest.then(() => {
          this.loading = false;
          this.$emit("reload");
          this.$emit("close");
          this.error_clear();
          this.$snotify.success(this.$t('acknowledgeAllTriggers'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.acknowledgeAllTriggersSidebar ul,
.acknowledgeAllTriggersSidebar li {
  list-style: none;
}
.is-invalid .m-checkbox > span {
  border-color: #f4516c !important;
}
.acknowledgeAllTriggersSidebar .sbx-google__submit {
  display: none;
}
.acknowledgeAllTriggersSidebar .sbx-google__input,
.acknowledgeAllTriggersSidebar .sbx-google__wrapper,
.acknowledgeAllTriggersSidebar .searchbox.sbx-google {
  width: 100%;
}
.acknowledgeAllTriggersSidebar .is-invalid .sbx-google__input {
  border: 1px solid red;
  box-shadow: none;
}
</style>