export const installationTypeMixin = {
    data: function () {
      return {
        installationTypeMixin_nameMapping: [
            {key: 'HG-OnsiteETL-ADVT', name: 'ADVT'},
            {key: 'HG-OnsiteETL-Connector', name: 'Connector' },
            {key: 'HG-OnsiteETL-T', name: 'Terminal'},
            {key: 'HG-OnsiteETL-SM', name: 'Skimovie'},
            {key: 'HG-OnsiteETL-SC', name: 'Speedcheck'},
            {key: 'HG-OnsiteETL-PS', name: 'Photostart'},
            {key: 'HG-OnsiteETL-PT', name: 'Phototrap'},
            {key: 'HG-OnsiteETL-PP', name: 'Photopoint'},
            {key: 'HG-OnsiteETL-MS-RC', name: 'Mediastreamer'},
            {key: 'HG-OnsiteETL-Panomax', name: 'Panocam'},
            {key: 'HG-OnsiteETL-WIFI', name: 'WIFI'},
            {key: 'HG-OnsiteETL-DestinationOS', name: 'DestinationOS' },
          { key: 'HG-OnsiteETL-Emma', name: 'Emma' },
          { key: 'HG-OnsiteETL-Competition', name: 'Competition' },
          { key: 'HG-OnsiteETL-ITService', name: 'IT Service' },
          { key: 'HG-OnsiteETL-SkilineOnline', name: 'Skiline Online' },
          ],         
      }
  },
  methods: {
    installationTypeMixin_installationTypeKey: function (installationType) {
      return this.installationTypeMixin_nameMapping.find(p => p.name === installationType).key;
    },
    installationTypeMixin_allInstallationTypes: function () {
        return this.installationTypeMixin_nameMapping.map(o => o.name);
      }
  }
}